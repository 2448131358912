import {MainPage} from "./components/MainPage/MainPage";
import "./App.css"
import React from "react";

export const baseUrl = "" //window.location.origin.includes('localhost') ? 'http://localhost:5000' : 'http://baseroad-stage.dock7.66bit.ru'

function App() {
    return (
        <div className="App">
            <MainPage/>
            <svg style={{visibility: "hidden", position: "absolute"}} width="0" height="0"
                 xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                    <filter id="goo_small">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur"/>
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                                       result="goo_small"/>
                        <feComposite in="SourceGraphic" in2="goo_small" operator="atop"/>
                    </filter>
                </defs>
            </svg>
            <svg style={{visibility: "hidden", position: "absolute"}} width="0" height="0"
                 xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                    <filter id="goo_medium">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="3" result="blur"/>
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                                       result="goo_medium"/>
                        <feComposite in="SourceGraphic" in2="goo_medium" operator="atop"/>
                    </filter>
                </defs>
            </svg>
            <svg style={{visibility: "hidden", position: "absolute"}} width="0" height="0"
                 xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                    <filter id="goo">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="6" result="blur"/>
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                                       result="goo"/>
                        <feComposite in="SourceGraphic" in2="goo" operator="atop"/>
                    </filter>
                </defs>
            </svg>
        </div>
    );
}

export default App;
