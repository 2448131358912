import {memo, useEffect, useState} from "react";
import {baseUrl} from "../../../App";

export const NewRegion = memo((region) => {
    const [newRegionImgUrl, setNewRegionImgUrl] = useState("");

    const backURL = baseUrl;
    useEffect(() => {
        if (region) {
            getNewRegionData();
        }
    }, [region])

    const getNewRegionData = async () => {
        const response = await fetch(`${backURL}/map/agglomeration/subjectDTO/${region.region}`);
        const data = await response.json();
        setNewRegionImgUrl((prevData) => data.innerMapFilePath);
    }
    return (
        <div>
            <img className={"new-region__img"} src={newRegionImgUrl} alt="" style={{width: "100%", height: "100%", zIndex: "100", position: "relative"}}/>
        </div>
    );
})