import "./Buttons.scss"
import React from 'react';

export const Button = ({onClick, className, name}) => {
    return (
        <div onClick={onClick} className={className}>
            <div className={"button-round"}>
                <button className={"button"}>
                    {name}
                </button>
            </div>
        </div>
    )

}