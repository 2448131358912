import "./Header.scss";
import {ReactComponent as Back} from "./../../assets/back.svg";
import {ReactComponent as LogoWithoutBorder} from "./../../assets/logo_without_border.svg";
import {ReactComponent as Logo} from "./../../assets/logo.svg";

export const Header = () => {
    return (
        <header className={"header"}>
            <div className="header__wrapper header__wrapper_back">
                <div className="header__logo">
                    <Logo className={"header__logo-logo"}/>
                </div>
                <h1 className={"header__title"}>Опорная сеть автомобильных дорог Российской Федерации</h1>
            </div>
        </header>
    );
}


export const HeaderInnerCart = () => {
    return (
        <header className={"header header_inner"}>
            <div className="header__wrapper header__wrapper_back">
                <div className="header__logo">
                    <LogoWithoutBorder className={"header__logo-logo"}/>
                    <button className={"header__btn-back"}>
                        <Back/>
                        <p>Вернуться<br/> на начальный<br/> экран</p>
                    </button>
                </div>
                <h1 className={"header__title"}>Опорная сеть автомобильных дорог Российской Федерации</h1>
            </div>
        </header>
    );
}