import {Header} from "../Header/Header";
import "./MainPage.scss";
import {Map, newRegionImgUrl} from "./Map/Map";
import {Alphabet} from "./Alphabet/Alphabet";
import {Button} from "../Buttons/Buttons";
import {CSSTransitionGroup} from "react-transition-group";

import {ReactComponent as Gerb} from "../../assets/gerb.svg";
import {useEffect, useState} from "react";
import {Opornonet} from "./Opornonet/Opornonet";
import {KeyFigures} from "./KeyFigures/KeyFigures";
import {Events} from "./Events/Events";
import {ReactComponent as Sound} from "./../../assets/back.svg";
import {ReactComponent as SoundOff} from "./../../assets/muted.svg";
import {Routes} from "./Routes/Routes";
import {FetchSoundAndPlay, useMute, useSound} from "../../utils/Music";
import {baseUrl} from "../../App";
import {WrapperModals} from "../utils/WrapperModals/WrapperModals";
import {Plan} from "./Plan/Plan";
import {NewRegion} from "./NewRegion/NewRegion";


export const MainPage = () => {
    useSound("/map/getfileAudioBack");
    const [region, changeRegion] = useState(null);
    const [key, setKey] = useState(null);

    //region Mute
    const [isMute, setMuteUnMute] = useMute();
    useEffect(() => {
        fetchGetIsMuted()
    }, [])

    const fetchMuteUnMute = async () => {
        try {
            await fetch(baseUrl + "/map/setmute")
            setMuteUnMute(!isMute);
        } catch { }
    };

    const fetchGetIsMuted = async () => {
        try {
            const data = await fetch(baseUrl + "/map/getmute")
            const text = await data.text()
            setMuteUnMute(text === "true");
        } catch {}
    };
    //endregion

    const modal = {
        about: <Opornonet changeKey={setKey} key={key}/>,
        key: <KeyFigures changeKey={setKey}/>,
        events: <Events changeKey={setKey}/>,
        plan: <Plan changeKey={setKey}/>,
        routes: <Routes changeKey={setKey} isMute={isMute}/>,
        newRegions: <NewRegion changeKey={setKey} region={region}/>
    }
    const sounds = {
        about: "/map/getaudiofile/AudioAboutBaseRoad",
        key: "/map/getaudiofile/AudioKeyIndicators",
        events: "/map/getaudiofile/AudioEvents",
        routes: "",
    }

    const buttonOnClick = (e, key) => {
        if (key !== null)
            FetchSoundAndPlay(sounds[key]);
        setKey(prevValue => key);
        changeRegion(prevValue => null)
    }

    return (
        <div className="main">
            <Header/>
            <Alphabet className="main__alphabet" changeRegion={changeRegion}/>
            <Map region={region} changeRegion={changeRegion} setKey={setKey}/>
            <div className="main__panel">
                <button className="main__sound-btn" onClick={() => {
                    fetchMuteUnMute();
                }}>
                    <div className="main__sound-btn-wrapper">
                        {
                            <a href="/"><Sound/></a>
                        }
                        <p>НАЗАД</p>
                    </div>
                </button>
                <Button onClick={(e) => buttonOnClick(e, "about")} key="about" className="main__btn"
                        name={"Что такое опорная сеть"}/>
                <Button onClick={(e) => buttonOnClick(e, "key")} key="key" className="main__btn"
                        name={"Этапы развития"}/>
                {/*<Button onClick={(e) => buttonOnClick(e, "events")} key="events" className="main__btn"*/}
                {/*        name={"Мероприятия"}/>*/}
                <Button onClick={(e) => buttonOnClick(e, "plan")} key="plan" className="main__btn"
                        name={"Пятилетний план"}/>
                <Button onClick={(e) => buttonOnClick(e, "routes")} key="routes" className="main__btn"
                        name={"Значимые объекты"}/>
                <Gerb style={{opacity: 0, display: "none"}} className={"main__svg"}/>
            </div>


            <CSSTransitionGroup
                transitionName="example"
                transitionEnterTimeout={500}
                transitionLeaveTimeout={300}>
                {!!key &&
                <WrapperModals changeKey={setKey} className={(key === "routes" || key === "newRegions") ? "big-modal" : ""}>
                    {!!key && modal[key]}
                </WrapperModals>
                }
            </CSSTransitionGroup>
        </div>
    );
};