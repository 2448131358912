import "./KeyFigures.scss";
import {ReactComponent as Svg2} from "../../../assets/road/emblem.svg";
import {ReactComponent as Svg3} from "../../../assets/road/emblem-shield.svg";
import {ReactComponent as Road} from "../../../assets/key-figures/road.svg";
import {ReactComponent as RoadW} from "../../../assets/key-figures/road_w.svg";
import {ReactComponent as LikeRoad} from "../../../assets/key-figures/like-road.svg";
import {ReactComponent as CarWifi} from "../../../assets/key-figures/car-wifi.svg";
import {ReactComponent as Grow} from "../../../assets/opornonet-def/growup.svg";
import {ReactComponent as Logo} from "../../../assets/logo_with_noborder.svg";
import {ReactComponent as People} from "../../../assets/people.svg";

import {memo} from "react";

export const KeyFigures = memo(() => {

    return (
        <div className="key-figures">
            <div className={"key-figures__first"}>
                <div className={"key-figures__first-wrapper"}>
                    <div>протяженность<br/>опорной сети</div>
                    <div><span>136</span> тыс. км</div>
                    <div>из них</div>
                    <div></div>
                    <div className={"key-figures__flex"}>
                        <div>федеральные<br/>трассы</div>
                        <Svg2/>
                    </div>
                    <div><span>64</span> тыс. км</div>
                    <div className={"key-figures__flex"}>
                        <div>региональные<br/>трассы</div>
                        <Svg3/>
                    </div>
                    <div><span>72</span> тыс. км</div>
                    <div className={"key-figures__title-bottom"}>
                        <div className={"key-figures__title-bottom_divider"}></div>
                        <div>ЦЕЛЬ К КОНЦУ 2027 ГОДА:</div>
                        <div className={"key-figures__title-bottom_divider"}></div>
                    </div>
                    <div className={"key-figures__bottom"}>
                        <LikeRoad/>
                        <div className={"key-figures__num"}>85&nbsp;<span>%</span></div>
                        <div className={"key-figures__num-text"}>ОПОРНОЙ СЕТИ<br/>В&nbsp;НОРМАТИВНОМ СОСТОЯНИИ</div>
                    </div>
                </div>
            </div>
            <div className={"key-figures__second"}>
                <h3 className={"key-figures__h3"}>3 ЭТАПА РАЗВИТИЯ ОПОРНОЙ СЕТИ</h3>
                <div className="key-figures__item">
                    <div className="key-figures__year">
                        <Road className="key-figures__svg"/>
                        <div>2022 год</div>
                    </div>
                    <div className="key-figures__numbers">
                        <div className="key-figures__number">
                            <Logo/>
                            <div className={"key-figures__p"}>Протяженность опорной сети — <span>136 тыс. км</span>
                            </div>
                        </div>
                        <div className="key-figures__number">
                            <People/>
                            <div className={"key-figures__p"}>Будут обеспечены транспортной связью<br/>по опорной сети
                                <span> 85 млн чел.</span>
                            </div>
                        </div>
                        <div className="key-figures__number">
                            <Grow/>
                            <div className={"key-figures__p"}>Прирост ВВП составит
                                <span> 4,7 трлн руб.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="key-figures__item">
                    <div className="key-figures__year">
                        <RoadW className="key-figures__svg"/>
                        <div>2030 год</div>
                    </div>
                    <div className="key-figures__numbers">
                        <div className="key-figures__number">
                            <Logo/>
                            <div className={"key-figures__p"}>Протяженность опорной сети — <span>195 тыс. км</span>
                            </div>
                        </div>
                        <div className="key-figures__number">
                            <People/>
                            <div className={"key-figures__p"}>Будут обеспечены транспортной связью<br/>по опорной сети
                                <span> 97 млн чел.</span>
                            </div>
                        </div>
                        <div className="key-figures__number">
                            <Grow/>
                            <div className={"key-figures__p"}>Прирост ВВП составит
                                <span> 5,2 трлн руб.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="key-figures__item">
                    <div className="key-figures__year">
                        <CarWifi className="key-figures__svg"/>
                        <div>2035 год</div>
                    </div>
                    <div className="key-figures__numbers">
                        <div className="key-figures__number">
                            <Logo/>
                            <div className={"key-figures__p"}>Протяженность опорной сети — <span>270 тыс. км</span>
                            </div>
                        </div>
                        <div className="key-figures__number">
                            <People/>
                            <div className={"key-figures__p"}>Будут обеспечены транспортной связью<br/>по опорной сети
                                <span> 103 млн чел.</span>
                            </div>
                        </div>
                        <div className="key-figures__number">
                            <Grow/>
                            <div className={"key-figures__p"}>Прирост ВВП составит
                                <span> 5,3 трлн руб.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
})