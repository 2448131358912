import "./Events.scss";
import {ReactComponent as Svg2} from "../../../assets/events/Ресурс 2.svg";
import {ReactComponent as Svg3} from "../../../assets/events/Ресурс 3.svg";
import {ReactComponent as Svg4} from "../../../assets/events/Ресурс 4.svg";
import {ReactComponent as Svg5} from "../../../assets/events/Ресурс 5.svg";
import {ReactComponent as Svg6} from "../../../assets/events/Ресурс 6.svg";
import {ReactComponent as Svg7} from "../../../assets/events/Ресурс 7.svg";
import {ReactComponent as Svg8} from "../../../assets/events/Ресурс 8.svg";
import {ReactComponent as Svg9} from "../../../assets/events/Ресурс 9.svg";
import {memo, useEffect, useRef} from "react";
import {WrapperModals} from "../../utils/WrapperModals/WrapperModals";
import {playMusic, stopMusic, useSound} from "../../../utils/Music";

export const Events = memo(() => {

    return (
            <div className="events">
                <h2 className={"events__h2"}>мероприятия на опорной сети</h2>
                <div className={"events__wrapper"}>
                    <h3 className={"events__h3"}>проекты, увеличивающие эффект<br/> от существующих маршрутов</h3>
                    <h3 className={"events__h3"}>мероприятия, формирующие<br/> новые маршруты</h3>
                    <div className="events__item">
                        <Svg5/>
                        <div>Строительство<br/>обходов городов</div>
                    </div>
                    <div className="events__item">
                        <Svg6/>
                        <div>Строительство новых скоростных автомобильных дорог <br/>(платных и бесплатных)</div>
                    </div>
                    <div className="events__item">
                        <Svg2/>
                        <div>Устранение<br/>узких мест</div>
                    </div>
                    <div className="events__item">
                        <Svg7/>
                        <div>Реализация масштабных инфраструктурных проектов<br/> государственного значения</div>
                    </div>
                    <div className="events__item">
                        <Svg3/>
                        <div>Объединение отдельных участков<br/>дорог в единую сеть</div>
                    </div>
                    <div className="events__item">
                        <Svg8/>
                        <div>Связь экономических центров, транспортно-логистических узлов, рекреационных и промышленных
                            зон
                            развития по кратчайшему расстоянию
                        </div>
                    </div>
                    <div className="events__item">
                        <Svg4/>
                        <div>Строительство мостов<br/>и путепроводов</div>
                    </div>
                    <div className="events__item">
                        <Svg9/>
                        <div>Инфраструктура для организации безопасного движения частично автоматизированного и
                            беспилотного
                            транспорта
                        </div>
                    </div>
                </div>
            </div>
    );
})