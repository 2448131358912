export const data = [
    {
        title: "А", values: [
            {value: "Алтайский край", id: "barnaul"},
            {value: "Амурская область", id: "blagoveshchensk"},
            {value: "Архангельская область", id: "arhangelsk"},
            {value: "Астраханская область", id: "astrahan"},
            {value: "", id: ""},
        ]
    },
    {
        title: "Б", values: [
            {value: "Белгородская область", id: "belgorod"},
            {value: "Брянская область", id: "bryansk"},
        ]
    },
    {
        title: "В", values: [
            {value: "Владимирская область", id: "vladimir"},
            {value: "Волгоградская область", id: "volgograd"},
            {value: "Вологодская область", id: "vologda"},
            {value: "Воронежская область", id: "voronezh"},
        ]
    },
    {
        title: "Д", values: [
            {value: "Донецкая Народная Республика"},
        ]
    },
    {
        title: "Е", values: [
            {value: "Еврейская автономная область", id: "birobidzhan"},
        ]
    },
    {
        title: "З", values: [
            {value: "Забайкальский край", id: "chita"},
            {value: "Запорожская область"},
        ]
    },
    {
        title: "И", values: [
            {value: "Ивановская область", id: "ivanovo"},
            {value: "Иркутская область", id: "irkutsk"},
        ]
    },
    {
        title: "К", values: [
            {value: "Кабардино-Балкарская Республика", id: "nalchik"},
            {value: "Калининградская область", id: "kaliningrad"},
            {value: "Калужская область", id: "kaluga"},
            {value: "Камчатский край", id: "petropavlovsk-kamchatskiy"},
            {value: "Карачаево-Черкесская Республика", id: "cherkessk"},
            {value: "Кемеровская область — Кузбасс", id: "kemerovo"},
            {value: "Кировская область", id: "kirov"},
            {value: "Костромская область", id: "kostroma"},
            {value: "Краснодарский край", id: "krasnodar"},
            {value: "Красноярский край", id: "krasnoyarsk"},
            {value: "Курганская область", id: "kurgan"},
            {value: "Курская область", id: "kursk"},
        ]
    },
    {
        title: "Л", values: [
            {value: "Ленинградская область", id: "gatchina"},
            {value: "Липецкая область", id: "lipeczk"},
            {value: "Луганская Народная Республика"},
        ]
    },
    {
        title: "М", values: [
            {value: "Магаданская область", id: "magadan"},
            {value: "Московская область", id: "krasnogorsk"},
            {value: "Мурманская область", id: "murmansk"},
        ]
    },
    {
        title: "Н", values: [
            {value: "Ненецкий автономный округ", id: "naryan-mar"},
            {value: "Нижегородская область", id: "novgorod"},
            {value: "Новгородская область", id: "velikiy-novgorod"},
            {value: "Новосибирская область", id: "novosibirsk"},
        ]
    },
    {
        title: "О", values: [
            {value: "Омская область", id: "omsk"},
            {value: "Оренбургская область", id: "orenburg"},
            {value: "Орловская область", id: "orel"},
        ]
    },
    {
        title: "П", values: [
            {value: "Пензенская область", id: "penza"},
            {value: "Пермский край", id: "perm"},
            {value: "Приморский край", id: "vladivostok"},
            {value: "Псковская область", id: "pskov"},
        ]
    },
    {
        title: "Р", values: [
            {value: "Республика Адыгея", id: "maikop"},
            {value: "Республика Алтай", id: "gorno-altaisk"},
            {value: "Республика Башкортостан", id: "ufa"},
            {value: "Республика Бурятия", id: "ulan-ude"},
            {value: "Республика Дагестан", id: "mahachkala"},
            {value: "Республика Ингушетия", id: "magas"},
            {value: "Республика Калмыкия", id: "elista"},
            {value: "Республика Карелия", id: "petrozavodsk"},
            {value: "Республика Коми", id: "syktyvkar"},
            {value: "Республика Крым", id: "cimferopol"},
            {value: "Республика Марий Эл", id: "yoshkar-ola"},
            {value: "Республика Мордовия", id: "saransk"},
            {value: "Республика Саха (Якутия)", id: "yakutsk"},
            {value: "Республика Северная Осетия — Алания", id: "vladikavkaz"},
            {value: "Республика Татарстан", id: "kazan"},
            {value: "Республика Тыва", id: "kyzyl"},
            {value: "Республика Хакасия", id: "abakan"},
            {value: "Ростовская область", id: "rostov-na-donu"},
            {value: "Рязанская область", id: "ryazan"},
        ]
    },
    {
        title: "С", values: [
            {value: "Самарская область", id: "samara"},
            {value: "Санкт-Петербург", id: "saintpetersburg"},
            {value: "Саратовская область", id: "saratov"},
            {value: "Сахалинская область", id: "yuzhno-sahalinsk"},
            {value: "Свердловская область", id: "ekaterinburg"},
            {value: "Севастополь", id: "sevastopol"},
            {value: "Смоленская область", id: "smolensk"},
            {value: "Ставропольский край", id: "stavropol"},
        ]
    },
    {
        title: "Т", values: [
            {value: "Тамбовская область", id: "tambov"},
            {value: "Тверская область", id: "tver"},
            {value: "Томская область", id: "tomsk"},
            {value: "Тульская область", id: "tula"},
            {value: "Тюменская область", id: "tyumen"},
        ]
    },
    {
        title: "У", values: [
            {value: "Удмуртская Республика", id: "izhevsk"},
            {value: "Ульяновская область", id: "ulyanovsk"},
        ]
    },
    {
        title: "Х", values: [
            {value: "Хабаровский край", id: "habarovsk"},
            {value: "Ханты-Мансийский автономный округ", id: "hanty-mansiysk"},
            {value: "Херсонская область"},
        ]
    },
    {
        title: "Ч", values: [
            {value: "Челябинская область", id: "chelyabinsk"},
            {value: "Чеченская Республика", id: "grozny"},
            {value: "Чувашская Республика", id: "cheboksary"},
            {value: "Чукотский автономный округ", id: "anadyr"},
        ]
    },
    {
        title: "Я", values: [
            {value: "Ямало-Ненецкий автономный округ", id: "salehard"},
            {value: "Ярославская область", id: "yaroslavl"},
        ]
    },
]