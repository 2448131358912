import "./Opornonet.scss";
import {ReactComponent as City} from "../../../assets/opornonet-def/city.svg";
import {ReactComponent as GrowUp} from "../../../assets/opornonet-def/growup.svg";
import {ReactComponent as TwoCars} from "../../../assets/opornonet-def/two-cars.svg";
import {ReactComponent as Low} from "../../../assets/opornonet-def/low.svg";
import {ReactComponent as OpornoLogo} from "../../../assets/opornonet-def/oporno-logo.svg";
import {ReactComponent as FederalRoad} from "../../../assets/opornonet-def/federal-road.svg";
import {ReactComponent as CountryRoad} from "../../../assets/opornonet-def/country-roads.svg";
import {ReactComponent as Road} from "../../../assets/key-figures/road.svg";
import {memo} from "react";

export const Opornonet = memo(() => {
    return (
            <div className="opornonet-def">
                <div className={"opornonet-def-def__text-block"}>
                    <div className="opornonet-def__item">
                        <div className={"opornonet-def__item-title"}>
                            <Low/>
                            <h3 className="opornonet-def__h3">ФЕДЕРАЛЬНЫЙ ЗАКОН ОТ 24.07.2023 № 374-ФЗ</h3>
                        </div>
                        <p className={"opornonet-def__item-text"}>Федеральным законом введено определение опорной сети автомобильных дорог, определены критерии отнесения автомобильных дорог общего пользования к опорной сети автомобильных дорог</p>
                    </div>
                    <div className="opornonet-def__item main__item">
                        <div className={"opornonet-def__item-title main__title"}>
                            <OpornoLogo/>
                            <h2 className="opornonet-def__h2 main__h2">Опорная сеть автомобильных дорог —</h2>
                        </div>
                        <p className="opornonet-def__p main__p">совокупность автомобильных дорог, обеспечивающая бесперебойное движение транспортных средств, транспортную связанность территорий РФ, единство ее экономического пространства.</p>
                    </div>
                    </div>
                <div className={"opornonet-def-def__criteria"}>
                    <h2 className="opornonet-def__criteria-h2">КРИТЕРИИ ОТНЕСЕНИЯ К ОПОРНОЙ СЕТИ</h2>
                    <div className={"opornonet-def-def__criteria-content"}>
                        <div className="opornonet-def__item">
                            <div className={"opornonet-def__item-title"}>
                                <FederalRoad/>
                                <h3 className="opornonet-def__bold">Автомобильные дороги общего пользования федерального значения</h3>
                            </div>
                        </div>
                        <div className="opornonet-def__item">
                            <div className={"opornonet-def__item-title"}>
                                <CountryRoad/>
                                <h3 className="opornonet-def__bold">Автомобильные дороги общего пользования регионального значения:</h3>
                            </div>
                            <ul>
                                <li>соединяют Москву со столицами субъектов РФ;</li>
                                <li>соединяют столицы субъектов РФ между собой и с городами с численностью населения <span> > 100 тыс. человек;</span></li>
                                <li>являются обходами городов с численностью населения <span> > 100 тыс. человек;</span></li>
                                <li>имеют интенсивность движения <span> > 10 тыс. автомобилей в сутки;</span></li>
                                <li>обеспечивают подъезд к автомобильным пунктам пропуска через госграницу РФ</li>
                            </ul>
                        </div>
                        <div className="opornonet-def__item">
                            <div className={"opornonet-def__item-title"}>
                                <Road/>
                                <h3 className="opornonet-def__bold">Иные автомобильные дороги, соответствующие установленным Правительством РФ критериям</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
})