import {baseUrl} from "../App";
import {useEffect, useState} from "react";

let audio = new Audio();
let _callback = null;

export const useMute = () => {
    const [isMute, muteUnMute] = useState(false);

    const setMuteUnMute = (bool) => {
        if (bool) {
            stopMusic();
            audio.volume = 0
        } else {
            audio.volume = 1;
        }
        muteUnMute(bool);
    }

    return [isMute, setMuteUnMute];
}

export const useSound = (url, method="POST", key) => {
    useEffect(() => {
        FetchSoundAndPlay(url,method);
        return () => {
            stopMusic()
        }
    }, [key])
};

export const FetchSoundAndPlay = async (url, method="POST") => {
    try {
        const sound = await getSoundJson(url, method);
        playMusic(sound);
    } catch {

    }
}

export const FetchTextSoundAndPlay = async (url, method="POST") => {
    try {
        const sound = await getSoundText(url, method);
        playMusic(sound);
    } catch {

    }
}

const getSoundJson = async (url, method="POST") => {
    const response = await fetch(`${baseUrl}${url}`, {
        method: method,
    })
    return await response.json();
}

const getSoundText = async (url, method="POST") => {
    const response = await fetch(`${baseUrl}${url}`, {
        method: method,
    })
    const data = await response.text();
    return data;
}

export function playMusic(url = "", callback) {
    audio.pause();
    audio.src = url;
    audio.currentTime = 0.0;
    audio.play();
    if (callback){
        _callback = callback;
        audio.addEventListener("ended", callback);
    }
}

export function stopMusic() {
    if (_callback){
        _callback();
        _callback = null;
    }
    audio.currentTime = 0.0;
    audio.src = "";
    audio.pause();
}