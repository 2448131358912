import './Plan.scss';
import {memo} from "react";
import {ReactComponent as Road} from "../../../assets/plan/road.svg";
import {ReactComponent as RoadWithBridge} from "../../../assets/plan/road_with_bridge.svg";
import {ReactComponent as FederalRoads} from "../../../assets/plan/federal_roads.svg";
import {ReactComponent as CountryRoads} from "../../../assets/plan/country_roads.svg";
import {ReactComponent as Roller} from "../../../assets/plan/roller.svg";
import {ReactComponent as Vest} from "../../../assets/plan/vest.svg";


export const Plan = memo(() => {
    return(
        <div className={"plan"}>
            <div className={"plan__titles-wp"}>
                <div className={"plan__title"}>
                    ПЯТИЛЕТНИЙ ПЛАН ДОРОЖНОЙ ДЕЯТЕЛЬНОСТИ<br/>
                    Утвержден Распоряжением Правительства РФ от 20 июня 2022 года  <span><span>№</span> 1601-р</span>
                </div>
            </div>

            <div className={"plan-wrapper"}>
                <div className={"plan__first"}>
                    <div className={"plan__first-wrapper"}>
                        <div className={"plan__first-cont-top"}>
                            <div className={"plan__first-definition"}>
                                <div className={"plan__first-definition-title"}>СРОКИ РЕАЛИЗАЦИИ:</div>
                                <div className={"plan__first-definition-description"}>2023-2027
                                    <span className={"plan__first-definition-description-text"}> гг.</span>
                                </div>
                            </div>
                            <div className={"plan__first-definition"}>
                                <div className={"plan__first-definition-title"}>ОБЪЕМ ФИНАНСИРОВАНИЯ:</div>
                                <div className={"plan__first-definition-description"}>13,2
                                    <span className={"plan__first-definition-description-text"}> трлн руб.</span>
                                </div>
                            </div>
                            <div className={"plan__first-definition"}>
                                <div className={"plan__first-definition-title"}>КОЛИЧЕСТВО МЕРОПРИЯТИЙ:</div>
                                <div className={"plan__first-definition-description"}>
                                    <span className={"plan__first-definition-description-text"}>более </span> 250
                                </div>
                            </div>
                        </div>
                        <div className={"plan__first-cont-bottom"}>
                            <div className={"plan__first-cont-bottom-wrapper"}>
                                <div className={"plan__first-cont-bottom-title"}>
                                    К КОНЦУ <span>2024</span> Г. В НОРМАТИВЕ:
                                </div>
                                <div className={"plan__first-cont-bottom-definition-wrapper"}>
                                    <div className={"plan__first-cont-bottom-definition"}>
                                        <Road/>
                                        <div className={"plan__first-cont-bottom-definition-title"}><span>85 %</span> дорог<br/> в <span>105</span> агломерациях</div>
                                    </div>
                                    <div className={"plan__first-cont-bottom-definition"}>
                                        <RoadWithBridge classname={"plan__first-cont-bottom-definition-special"}/>
                                        <div className={"plan__first-cont-bottom-definition-title"}><span>53 %</span> региональных<br/> дорог</div>
                                    </div>
                                </div>
                            </div>

                            <div className={"plan__first-cont-bottom-wrapper"}>
                                <div className={"plan__first-cont-bottom-title"}>
                                    К КОНЦУ <span>2027</span> Г. В НОРМАТИВЕ:
                                </div>
                                <div className={"plan__first-cont-bottom-definition"}>
                                    <Road/>
                                    <div className={"plan__first-cont-bottom-definition-title"}><span>85 %</span> опорной сети дорог</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"plan__second"}>
                    <div className={"plan__second-wrapper"}>
                        <div className={"plan__second-title"}>план работ на опорной сети автомобильных дорог российской&nbsp;федерации</div>
                        <div className={"plan__second-cont-top"}>
                            <div className={"plan__second-cont-top-left"}>
                                <div className={"plan__second-cont-top__title"}>
                                    <FederalRoads/>
                                    <span>федеральные дороги</span>
                                </div>
                                <div className={"plan__second-cont-top-flexwp"}>
                                    <div className={"plan__second-cont-top__block"}>
                                        <div className={"plan__second-cont-top__block-top"}>
                                            <Roller/>
                                            <span>строительство и&nbsp;реконструкция</span>
                                        </div>
                                        <div className={"plan__second-cont-top__block-number"}>1 593,8 <span>км</span></div>
                                    </div>
                                    <div className={"plan__second-cont-top__block"}>
                                        <div className={"plan__second-cont-top__block-top"}>
                                            <Vest/>
                                            <span>капитальный ремонт</span>
                                        </div>
                                        <div className={"plan__second-cont-top__block-number"}>3 734 <span>км</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className={"plan__second-cont-top-right"}>
                                <div className={"plan__second-cont-top__title"}>
                                    <CountryRoads/>
                                    <span>региональные дороги</span>
                                </div>
                                <div className={"plan__second-cont-top-flexwp"}>
                                    <div className={"plan__second-cont-top__block"}>
                                        <div className={"plan__second-cont-top__block-top"}>
                                            <Roller/>
                                            <span>строительство и&nbsp;реконструкция</span>
                                        </div>
                                        <div className={"plan__second-cont-top__block-number"}>125,8 <span>км</span></div>
                                    </div>
                                    <div className={"plan__second-cont-top__block"}>
                                        <div className={"plan__second-cont-top__block-top"}>
                                            <Vest/>
                                            <span>ремонт и&nbsp;капитальный&nbsp;ремонт</span>
                                        </div>
                                        <div className={"plan__second-cont-top__block-number"}>5 169,2 <span>км</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"plan__second-cont-bottom"}>
                            <div className={"plan__second-cont-bottom-title"}>ЗАДАЧИ:</div>
                            <ul className={"plan__second-cont-bottom-tasks"}>
                                <li className={"plan__second-cont-bottom-task"}>
                                    Строительство обходов городов
                                </li>
                                <li className={"plan__second-cont-bottom-task"}>
                                    Устранение узких мест
                                </li>
                                <li className={"plan__second-cont-bottom-task"}>
                                    Строительство мостов и путепроводов
                                </li>
                                <li className={"plan__second-cont-bottom-task"}>
                                    Строительство новых скоростных дорог (платных и бесплатных)
                                </li>
                                <li className={"plan__second-cont-bottom-task"}>
                                    Реализация объектов, предусмотренных поручениями Президента РФ и&nbsp;Правительства РФ
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})