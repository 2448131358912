import "./Map.scss";
import React, {useEffect, useState} from "react";
import {OutsideAlerter} from "../../utils/OutsideAlerter";
import "../../../assets/road/emblem.svg"
import {ReactComponent as Roads} from "../../../assets/roads.svg";
import {ReactComponent as MapId} from "../../../assets/map_id.svg";

import {Button} from "../../Buttons/Buttons";
import {baseUrl} from "../../../App";

import Back from "./../../../assets/back-corrected.mp4"
import {FetchSoundAndPlay, FetchTextSoundAndPlay, stopMusic} from "../../../utils/Music";
import {NewRegion} from "../NewRegion/NewRegion";
//import { act } from "react-dom/test-utils";

let _lastElem = null;

export const Map = ({region, changeRegion, setKey}) => {

    const [isEmblemOpen, changeIsEmblemOpen] = useState(false);
    const [coordinates, changeCoords] = useState({x: 0, y: 0});
    //todo: объеденить в одно 2нижних хука
    const [urlEmblem, changeUrlEmblem] = useState("");
    const [textEmblem, changeTextEmblem] = useState("");

    const MapRef = React.createRef();
    //Навесить событие Редиректа к главной на стенде
    useEffect(() => {
        fetchGetIsStand();
    }, []);

    //Навесить события на регионы карты
    useEffect(() => {
        const setRegionAndCoordsEmblem = (e) => {
            const region = e.target.parentNode.dataset.id
            if (!region) return null;
            changeRegion(region);
            changeCoords(_coordsClickRelativeMap(e));
            if (!(region == "kherson" || region == "lugansk" || region == "donetsk" || region == "zaporozhye")) {
                const coords = e.target.parentNode.getBoundingClientRect();
                if (region === "yakutsk")
                    return changeCoords({x: coords.right * 0.88, y: coords.bottom * 0.6});
            } else {
                setKey("newRegions");
            }
            // changeRegion(prevValue => null);

        };
        AddEventListenerOnMapRef(MapRef.current, setRegionAndCoordsEmblem);
        
    }, []);

    const toggleOpenRegion = (target) => {
        if (!target.hasAttribute("data-id"))
            return;
        window.document.querySelectorAll(`[data-id="${target.getAttribute("data-id")}"]`)
            .forEach((e) => e.classList.add("map__path_active"));
        _lastElem = target;
        changeIsEmblemOpen(true)
    }


    useEffect(() => {
        if (_lastElem) {
            window.document.querySelectorAll(`[data-id="${_lastElem.getAttribute("data-id")}"]`)
                .forEach((e) => e.classList.remove("map__path_active"));
        }
        if (!region)
            return;
        //todo: ниже повторяется логика проверки
        const element = window.document.querySelector(`g[data-id="${region}"]`);
        if (!element)
            return;
        toggleOpenRegion(element);
        const map = window.document.querySelector(`.map`);
        const coords = element.getBoundingClientRect();
        const coordsWrapperBlock = MapRef.current.getBoundingClientRect();
        if (region === "yakutsk")
            changeCoords({x: coords.right * 0.88, y: coords.bottom * 0.6});
        else
            changeCoords({x: coords.x - coordsWrapperBlock.left, y: coords.y + coords.height / 2 + map.scrollTop});
    }, [region])

    // useEffect(() => {
    //     const urlParams = new URLSearchParams(window.location.search);
    //     const newRegionParam = urlParams.get('regionModal');
    //     if (newRegionParam === 'open') {
    //         // setKey(prevValue => "newRegions");
    //         console.log("hrjkfjfhjgj")
    //     }
    // }, []);

    return (
        <div className="map">
            <div className={"map__map-wrapper"}>
                <Roads className="map__roads"/>
                <MapId ref={MapRef} className="map__svg-map"/>
                <div className={"map__gerb " + (isEmblemOpen ? "map__gerb_open" : "")}
                     style={{top: coordinates.y, left: coordinates.x}}>
                    {urlEmblem && <img className={"map__gerb-img"} src={urlEmblem}/>}
                    {textEmblem?.length > 0 && <p className={"map__gerb-p"}>{textEmblem}</p>}
                </div>
            </div>
            {isEmblemOpen && <Modal isOpen={isEmblemOpen} region={region} changeIsOpen={changeIsEmblemOpen}
                                    changeRegion={changeRegion} changeUrlEmblem={changeUrlEmblem}
                                    changeTextEmblem={changeTextEmblem}/>}
            <div className={"map__video"}>
                <video src={Back} autoPlay={true} muted={true} loop={true}/>
            </div>
        </div>
    );
}

const Modal = ({isOpen, changeIsOpen, changeRegion, region, changeUrlEmblem, changeTextEmblem}) => {
    const backURL = baseUrl;
    const [agglomerationData, setAgglomerationData] = useState(null);
    useEffect(() => {
        if (region) {
            getAgglomerationData();
            getAgglomerationEmblem();
            FetchTextSoundAndPlay(`/map/agglomeration/getmainmapaudio/${region}`, "GET");
        }
        return () => stopMusic();
    }, [region])
    if (region == null) return null;

    const getAgglomerationData = async () => {
        const response = await fetch(`${backURL}/map/agglomeration/subjectDTO/${region}`);
        const data = await response.json();
        setAgglomerationData(data)
    }
    const getAgglomerationEmblem = async () => {
        changeUrlEmblem(null);
        changeTextEmblem(null)
        const response = await fetch(`${backURL}/map/agglomeration/gerb/${region}`)
        const data = await response.json();
        changeUrlEmblem(data.shortPath);
        changeTextEmblem(data.agglomeration);
    }

    const closeModal = () => {
        if (_lastElem)
            window.document.querySelectorAll(`[data-id="${_lastElem.getAttribute("data-id")}"]`).forEach((e) => e.classList.remove("map__path_active"))
        changeRegion(null);
        changeIsOpen(false);
    }

    if (!agglomerationData) return null

    const {federalRoadsLength_BaseRoads, regionalRoadsLength_BaseRoads, baseRoadsLegnth, useInnerMapFile} = agglomerationData

    if (!useInnerMapFile) {
        return <OutsideAlerter onOutside={() => isOpen ? closeModal() : null}>
            <div className={"info-map " + (isOpen ? "info-map_open" : "")}>
                <div>протяженность <br/>опорной сети</div>
                <GetThousandsAndOther number={baseRoadsLegnth}/>
                <div>из них</div>
                <div></div>
                <div>федеральные <br/>трассы</div>
                <GetThousandsAndOther number={federalRoadsLength_BaseRoads}/>
                <div>региональные <br/>трассы</div>
                <GetThousandsAndOther number={regionalRoadsLength_BaseRoads}/>
                <a href={`${backURL}/innermap/${region}`}><Button className={"info-map__button"} name={"Подробнее..."}/></a>
            </div>
        </OutsideAlerter>
    } else {
        return "";
    }
};

const GetThousandsAndOther = ({number}) => {
    number = Math.round(number);
    const other = number.toString().slice(-3);
    const thousands = (number - other) / 1000;

    return <div>{thousands > 0 && <span className={"info-map__thousands"}>{thousands}</span>}
        <span className={"info-map__other"}>{other}</span>км</div>
}

const AddEventListenerOnMapRef = (elem, toggleOpen) => {
    if (!elem)
        throw new Error();
    let arrayG = elem.querySelectorAll("g");
    arrayG.forEach(g => {
        g.classList.add("map__g");
        g.onclick = toggleOpen;
    })
};


const _coordsClickRelativeMap = (e) => {
    const map_mapWrapper = e.target.parentNode.parentNode.parentNode.parentNode;
    const map = map_mapWrapper.parentNode;
    const map_mapWrapperCoords = map_mapWrapper.getBoundingClientRect();
    return {x: e.pageX - map_mapWrapperCoords.left, y: e.pageY + map.scrollTop};
};

const fetchGetIsStand = async () => {
    try {
        const data = await fetch(baseUrl + "/map/getsettingisstand")
        const text = await data.text()
        RedirectToMain(text === "true");
        
    } catch {}
};

const RedirectToMain = (option) => {
    if(option){
        const noActive = 120;
        let active = 0; 
    
        setInterval(Timer, 1000); 
        setInterval(Redirect, 1000); 
        document.onmousemove = ActiveUser; 
        document.onmousedown = ActiveUser;
        document.ontouchstart = ActiveUser;
        document.ontouchend = ActiveUser;
        function Timer() {
            active++;
        }
        function ActiveUser() {
            active = 0; 
        }
        function Redirect() {
            if (active >= noActive) { 
                window.location.href = "/";
                return;
            }
        }
    }
    else{
        //Убираем кнопочку back-а на главную
        document.getElementsByClassName('main__sound-btn-wrapper')[0].style.display = "none";
        return;
    }
};
