import "./Routes.scss";
import {WrapperModals} from "../../utils/WrapperModals/WrapperModals";
import {ReactComponent as Arrow} from "./../../../assets/route_arrow.svg";
import {ReactComponent as Play} from "./../../../assets/routes_play.svg";
import {ReactComponent as Alice} from "./../../../assets/alice.svg";
import {memo, useEffect, useState} from "react";
import {baseUrl} from "../../../App";
import {playMusic, stopMusic, useMute} from "../../../utils/Music";


const getRoutesData = async (setData) => {
    const response = await fetch(`${baseUrl}/map/getroutes`)
    const data = await response.json();
    setData(data);
}

export const Routes = memo(({isMute}) => {
    const [isVideoOpen, toggleVisibility] = useState(false);
    const [index, setIndex] = useState(0);
    const [data, setData] = useState([]);

    useEffect(() => {
        getRoutesData(setData);
        return () => {
            stopMusic();
        }
    }, []);

    useEffect(() => {
        stopMusic();
    }, [index])


    if (data.length === 0)
        return null;
    return (
        <div className="routes">
            <MainContent data={data} index={index} setIndex={setIndex} toggleVisibility={toggleVisibility}
                         isMute={isMute}/>
            {isVideoOpen &&
            <section className={"routes-video"}>
                <div className={"routes-video__wrapper"}>
                    <div className={"routes-video__close"} onClick={() => toggleVisibility(false)}>&#10006;</div>
                    <video src={data[index]?.fileVideo} autoPlay={false} controls={true}/>
                </div>
            </section>
            }
        </div>
    );
});

const MainContent = memo(({data, index, setIndex, toggleVisibility, isMute}) => {
    const increase = () => {
        setIndex(index + 1);
    }
    const reduce = () => {
        setIndex(index - 1);
    }
    const currRoute = data[index];

    const Images = data.map((e, key) => {
        return <img key={key} src={e?.fileImage} className={"routes__img"}/>
    })

    return (<><h2 className={"routes__h2"}>{currRoute?.name}</h2>
        <div className={"routes__round"}>
            <div className={"routes__img-wrapper"}>
                <div className={"routes__imgs-wrapper"} style={{transform: `translateX(${-100 * index}%)`}}>
                    {Images}
                </div>
            </div>
        </div>
        <section className={"routes__control-btns"}>
            <div className={"routes__media"}>
                <AliceComp currRoute={currRoute} isMute={isMute}/>
                {currRoute?.fileVideo &&
                <div className={"routes__play routes__btn"} onClick={() => toggleVisibility(true)}>
                    <Play className={"routes__play-btn "}/>
                    <div className={"routes__label"}>РОЛИК</div>
                </div>
                }
            </div>
            <section className={"routes__flipping"}>
                <div
                    className={"routes__flipping-item routes__btn " + (index === 0 ? "routes__flipping-item_hidden" : "")}
                    onClick={reduce}>
                    <Arrow className={"routes__left"}/>
                    <div className={"routes__label"}>НАЗАД</div>
                </div>
                <div
                    className={"routes__flipping-item routes__btn " + (data.length === 0 || index === (data.length - 1) ? "routes__flipping-item_hidden" : "")}
                    onClick={increase}>
                    <Arrow className={"routes__right"}/>
                    <div className={"routes__label"}>ВПЕРЕД</div>
                </div>
            </section>
        </section>
    </>)
});

const AliceComp = ({currRoute, isMute}) => {
    const [isPlaying, Play] = useState(false);
    return <Alice style={{visibility: !currRoute?.fileAudio && "hidden"}}
                  className={"routes__alice " + (isPlaying ? "routes__alice_animate" : "")}
                  onClick={() => {
                      if (isMute)
                          return;
                      Play(true);
                      playMusic(currRoute?.fileAudio, () => Play(false))
                  }
                  }/>
}